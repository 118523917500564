import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apis from "../../services";
import { useLocation } from "react-router-dom";
import { ConfirmPasswordReset } from "../../helper/validationSchema";
import { useSelector } from "react-redux";

const ConfirmPassword = () => {
  // const [email, setEmail] = useState("");
  const location = useLocation();
  const navigate = useNavigate();


  let { user } = useSelector((store) => store.user);

  useEffect(() => {
    if(user) {
      // navigate("/");
    }
  }, [user]);


  const queryParams = new URLSearchParams(location.search);
  const value = queryParams.get("email");
  // console.log("VALUE EMAIL", value);

  const { mutate, isPending } = useMutation({
    mutationFn: apis.authResetPassword,
    onError: function ({ message }) {
      console.log("MESSAGE ERROR:", message);
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data, status }) => {
      console.log("data:", data);
      if (data?.status) {
        navigate("/login");
      }
    },
  });

  const handleSubmit = (values) => {
    console.log("VALUES:", values);
    console.log("VALUE EMAIL::", value);
    if (values && value) {
      mutate({ ...values, email: value });
    }
  };
  return (
    <Formik
      initialValues={{ password: "", password_confirmation: "" }}
      onSubmit={handleSubmit}
      validationSchema={ConfirmPasswordReset}
    >
      {({ errors, touched }) => (
        <div className="confirm-pasword">
          {/* <div className="top-logo">
            <Link to="/">
              <img
                src="./assets/images/infyom.png"
                alt="logo"
                className="logimg"
              />
            </Link>
          </div> */}
          <Form method="POST">
            <div className="forgot-input-container">
              <div className="input-wrapper">
                <label> Password</label>
                <Field
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter Your New Password"
                  // value={email}
                  // onChange={(e) => setEmail(e.target.value)}
                  className={errors.email && touched.email ? "error-input" : ""}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="input-wrapper">
                <label style={{ marginTop: "12px" }}>Confirm Password</label>
                <Field
                  type="password"
                  name="password_confirmation"
                  id="confirm_password"
                  placeholder="Enter Your New Confirm Password "
                  // value={email}
                  // onChange={(e) => setEmail(e.target.value)}
                  className={
                    errors.password_confirmation && touched.password_confirmation
                      ? "error-input"
                      : ""
                  }
                />
                <ErrorMessage
                  name="password_confirmation"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="forgot-btn-wrapper">
                <button type="submit">
                  {isPending ? "Loading..." : "Send"}
                </button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default ConfirmPassword;
