import { Route, Routes, useLocation } from "react-router-dom";
import {
  Header,
  Footer,
  SignUp,
  Login,
  ProtectedRoute,
} from "./components/index";
import {
  Cart,
  Checkout,
  Coupon,
  Favorite,
  Home,
  JtcToken,
  Order,
  ProductDetail,
  Profile,
  Promo,
  Reward,
  ViewAll,
  CartProceedTimer,
  Wallet,
  Referral,
  Notification,
} from "./screens/index";
import { ScrollToTop } from "./components";

//styles
import "react-phone-number-input/style.css";
import "./assets/css/style.css";
import "./assets/css/login.css";
import "./assets/css/responsive.css";
import "./App.css";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ConfirmPassword from "./components/Auth/ConfirmPassword";
import { useEffect, useState } from "react";
import ChangePassword from "./components/Auth/ChangePassword";

function App() {
  const location = useLocation();
  // const [isHomePage, setIsHomePage] = useState(false);
  // useEffect(() => {
  //   const storedIsHomePage = localStorage.getItem("isHomePage");
  //   setIsHomePage(storedIsHomePage === "true");
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem("isHomePage", isHomePage);
  // }, [isHomePage]);

  // useEffect(() => {
  //   setIsHomePage(location.pathname === "/");
  // }, [location.pathname]);

  // const headerStyle = {
  //   backgroundColor: isHomePage ? "rgba(62, 62, 62, 0.8)" : "#fff",
  // };
  return (
    <div className="App">
      <ScrollToTop />
      <Header  />
      <Routes key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="/viewall/:categoryId" element={<ViewAll />} />
        <Route path="/reward" element={<Reward />} />
        <Route path="/coupon" element={<Coupon />} />
        <Route path="/order" element={<Order />} />
        <Route path="/jtc-token" element={<JtcToken />} />
        <Route path="/product/:productdetailId" element={<ProductDetail />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/favorite" element={<Favorite />} />
        <Route path="/promo" element={<Promo />} />
        <Route path="/checkout" element={<Checkout />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/profile" element={<Profile />} />
        </Route>

        <Route path="/order-status" element={<CartProceedTimer />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/referral" element={<Referral />} />
        <Route path="/notification" element={<Notification />} />

        {/* AUTH ROUTES */}
        <Route path="/signup" element={<SignUp />} />

        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/confirm-password" element={<ConfirmPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
