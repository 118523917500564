import React from "react";
import { ScreenTopBanner } from "../components";
import { useQuery } from "@tanstack/react-query";
import apis from "../services";
import RewardCard from "../components/RewardCard";

const Wallet = () => {
  const {
    isLoading,
    error,
    refetch,
    data: { data: getUserWallet } = {},
  } = useQuery({
    queryKey: ["getUserWallet"],
    queryFn: () => apis.getUserWallet(),
    onError: (error) => {
      console.error("Error geting user wallet :", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  console.log("USER WALLET", getUserWallet);
  
  
  const {
    isLoading:isLoadingWallet,
    data: { data: getReferral } = {},
  } = useQuery({
    queryKey: ["getReferral"],
    queryFn: () => apis.getReferral(),
    onError: (error) => {
      console.error("Error geting Referral :", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  
  
  console.log("getReferral", getReferral);


  return (
    <section className="" id="jtc-token">
      <ScreenTopBanner title="WALLET" />
      <div className="wallet-wrapper">
        <div className="left">
          <div className="heading-wrapper">
            <p className="wallet-head">AVAILABLE BALANCE</p>
            <h2 className="price-wallet">
              $
              {getUserWallet?.availableTokens !== null &&
              getUserWallet?.availableTokens !== undefined
                ? getUserWallet?.availableTokens
                : 0}
            </h2>
            <p className="wallet-mexican">MEXICAN PESOS</p>
          </div>
        </div>
        <div className="right">
          <img src="/assets/images/token-coin.png" alt="token" />
        </div>
      </div>
<div id="coupon-parent">
<div className="reward-left">  


<div className="wallet-history">
        <h3>Transaction History</h3>
        </div>


<div className="coupon-wrapper" style={{display:"block"}}>
  
              <RewardCard data={getReferral} />
            </div>
</div>

</div>

    </section>
  );
};

export default Wallet;
