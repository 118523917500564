import React from "react";

const JtcTokenCard = ({ data, handleModalOpen, setTokenData }) => {
  console.log("DATATOKEN:", data);
  return (
    <>
      {
        <div className="card">
          <div className="top">
            <p>{data?.tokens}</p>
            <img src="/assets/images/token-img.png" alt="card1" width="100%" />
          </div>
          <div className="center">
            <h3>{data?.name}</h3>
            <p>{data?.description}</p>
            {/* <div className="btn-jtc-token-wrapper">
              <button>View all</button>
            </div> */}
          </div>
          <div className="bottom">
            <button
              onClick={() => {
                handleModalOpen();
                setTokenData(data);
              }}
            >
              ${data?.amount} MXN
            </button>
          </div>
        </div>
      }
    </>
  );
};

export default JtcTokenCard;
