import React from "react";

const RewardCard = ({ data }) => {
  return (
    <>
      {data && data?.wallet_transactions.length > 0 ? (
        data?.wallet_transactions?.map((data, index) => {
          return (
            <div className="main-coupon" key={index}>
              {/* <p className="time">Today 02:50 PM </p> */}
              <div className="coupon ">
                <div className="left">
                  <img src="/assets/images/Frame.png" alt="product" />
                </div>
                <div className="right">
                  <div className="one">
                    <h2>{data?.message}</h2>
                    {/* <div className="two">
                    <p>QTY: 02</p>
                    <p>$3000.00</p>
                  </div> */}
                    <p className="earn-reward">
                      Earn:<span> {data?.points} JTC Points</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p className="no-order#coupon-parent .coupon-wrapper d-flex justify-content-center">No Transaction Available</p>
      )}
    </>
  );
};

export default RewardCard;
