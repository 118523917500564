import { createSlice } from "@reduxjs/toolkit";
import reduxApis from "../api";

const initialState = {
  loading: false,
  product: [],
  productDetails: {},
  cart: null,
  error: null,
};

const productSlice = createSlice({
  name: "store",
  initialState: initialState,
  reducers: {
    setProducts: (state, action) => {
      state.store = action.payload;
    },
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    clearCart: (state, action) => {
      state.cart = null;
    },
    setProductsDetails: (state, action) => {
      state.productDetails = action.payload;
    },
  }, // You can add any synchronous actions here if needed
  extraReducers: (builder) => {
    // Handles the asynchronous action reduxApis.getAllStore
    builder
      .addCase(reduxApis.getAllProductWithCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(reduxApis.getAllProductWithCategory.fulfilled, (state, action) => {
        state.loading = false;
        // console.log(action?.payload?.data?.data , "REDUX PRODUCT DATA")
        state.product = action?.payload?.data?.data;
      })
      .addCase(reduxApis.getAllProductWithCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handles the asynchronous action reduxApis.getOneStore
    builder
      .addCase(reduxApis.getOneStore.pending, (state) => {
        state.loading = true;
      })
      .addCase(reduxApis.getOneStore.fulfilled, (state, action) => {
        state.loading = false;
        state.productDetails = action.payload.data.storeData;
      })
      .addCase(reduxApis.getOneStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { setProducts,setProductsDetails ,setCart ,clearCart} = productSlice.actions;

export default productSlice.reducer;