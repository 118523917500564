// stripe checkout popup

import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { IoIosWarning } from "react-icons/io";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";
import {
  CardElement,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
} from "@stripe/react-stripe-js";
import apis from "../services";
// import SuccessModal from "./Modal/SuccessModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { clearCart } from "../redux/slice/productSlice";
// import { ThreeDots } from "react-loader-spinner";

export default function CheckoutFormToken({
  handlePaymentModal,
  dataProceed,
  dataToken,
}) {
  console.log("DATA CHECKOUTFORM TOKEN", dataToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const cardStyle = {
    style: {
      base: {
        color: "#000",
        fontFamily: "Poppins, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        border: "1px solid red",
        "::placeholder": {
          color: "#3e3e3e",
        },
      },
      invalid: {
        fontFamily: "Poppins, sans-serif",
        color: "#FF3333        ",
        iconColor: "#FF3333        ",
      },
    },
  };

  const { user } = useSelector((store) => store.user);

  const [clientSeceretKey, setClientSecretKey] = useState(null);

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const { mutate, isPending, data } = useMutation({
    mutationFn: apis.jtcTokenCheckout,
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data: checkout, status }) => {
      console.log(
        "CART CHECKOUT",
        checkout?.order?.original?.data?.clientSecret
      );
      setClientSecretKey(checkout?.order?.original?.data?.clientSecret);
    },
  });
  console.log("clientSeceretKey", clientSeceretKey);
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const element = elements.getElement(CardNumberElement);
    console.log("element", element);
    if (element?._complete) {
      let res;
      res = await apis.jtcTokenCheckout({ token_id: dataToken.id });
      console.log("response:", res);
      //   console.log("res?.data?.client_secret_key", res?.data?.client_secret_key);
      if (res?.data?.client_secret_key) {
        setClientSecretKey(res?.data?.client_secret_key);
        const payload = await stripe.confirmCardPayment(
          res?.data?.client_secret_key,
          {
            payment_method: {
              card: element,
            },
          }
        );
        console.log("PAYLOAD :", payload);
        if (payload) {
          const resConfirmTransaction = await apis.jtcTokenConfirm({
            token_id: dataToken.id,
            payment_id: payload?.paymentIntent?.id,
          });
          setProcessing(false)
          console.log("RES CONFIRM TRANSACTION:", resConfirmTransaction);
          toast.success("Token purchased successfully")
          navigate("/")
        }
      }

      //   const payload = await stripe.confirmCardPayment({
      //     payment_method: {
      //       card: element,
      //     },
      //   });
      //   console.log("PAYLOAD", payload);

      //   if (dataProceed?.promo_code == "") {
      //     let { promo_code, ...rest } = dataProceed;
      //     res = await apis.cartCheckout(rest);
      //   } else {
      //     res = await apis.cartCheckout(dataProceed);
      //   }
      //   // console.log(res, "RESSSSSSSSSSSS");

      //   console.log(data, "data");
      //   if (res?.data?.order?.original?.data?.clientSecret) {
      //     const payload = await stripe.confirmCardPayment(
      //       res?.data?.order?.original?.data?.clientSecret,
      //       {
      //         payment_method: {
      //           card: element,
      //         },
      //       }
      //     );

      //   if (payload) {
      //   console.log("payloadpayload IDDD", payload?.paymentIntent?.id);
      //   const res = await apis.orderConfirm({
      //     payment_id: Number(payload?.paymentIntent?.id),
      //   });
      //   console.log("RES", res);
      //   toast.success("Order placed successfully");
      //   dispatch(clearCart());
      //   handlePaymentModal();
      //   navigate("/order");
      // }
      //   }
    }

    setProcessing(false);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit} className="pay_form">
        <div className="mb-3">
          <label className="fw-semibold mb-2 d-block position-relative">
            <span class="text-purple position-absolute top-50 end-0 translate-middle-y fs-4 mt-2 pt-1 me-3"></span>
            <span className="card-num-head">Card Number</span>
            <CardNumberElement
              options={cardStyle}
              onChange={handleChange}
              className="pay_input "
            />
          </label>
        </div>
        <div className="d-flex mb-3">
          <div className="w-100">
            <label className="fw-semibold mb-2 d-block">
              <span className="card-num-head"> Expiration Date</span>
              <CardExpiryElement
                options={cardStyle}
                onChange={handleChange}
                className="pay_input"
              />
            </label>
          </div>
          <div className="ms-3 w-100">
            <label className="fw-semibold mb-2 d-block">
              <span className="card-num-head">CVC</span>
              <CardCvcElement
                options={cardStyle}
                onChange={handleChange}
                className="pay_input"
              />
            </label>
          </div>
        </div>
        <button
          disabled={processing || disabled || succeeded}
          id="submit"
          className="btn   w-100 btn-pay d-flex justify-content-center align-items-center"
        >
          <span id="button-text">{processing ? <>Loading</> : `Pay Now`}</span>
        </button>

        {error && (
          <div className="card-error text-danger mt-3" role="alert">
            {error}
          </div>
        )}
      </form>
    </>
  );
}
