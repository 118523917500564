import React from "react";
import apis from "../services";
import { useQuery } from "@tanstack/react-query";
import { ScreenTopBanner } from "../components";

const Notification = () => {
  const {
    isLoading,
    error,
    refetch,
    data: { data: getNotify } = {},
  } = useQuery({
    queryKey: ["getNotify"],
    queryFn: () => apis.getNotify(),
    onError: (error) => {
      console.error("Error geting Order History:", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  // console.log("getNotify", getNotify);
  return (
    <>
      {isLoading ? (
        <>
          <div className="loaderWrapper-cart">
            <div className="loader"></div>
          </div>
        </>
      ) : (
        <section className="container-fluid" id="jtc-token">
          <ScreenTopBanner title="NOTIFICATION" />
          <div id="coupon-parent" className="coupon-page">
            {getNotify?.data.notifications.length > 0 ? (
              getNotify?.data.notifications?.map((data, index) => {
                //   console.log("DATA:", data);
                return (
                  <div className={`coupon ${data?.is_expired}`} key={index}>
                    <div className="left">
                      <img src="/assets/images/Frame.png" alt="product" />
                    </div>
                    <div className="right">
                      <div className="one">
                        <h2>{data?.message} Token</h2>
                        <span>{data?.title}</span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="no-order">No Notification </p>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default Notification;
