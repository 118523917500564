import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { FaRegEyeSlash } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa6";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { SignupValidationSchema } from "../../helper/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apis from "../../services";
import { VerifyOTPModal } from "../modals/VerifyOTPModal";
import { setUser } from "../../redux/slice/userSlice";
import { OtpSuccessModal } from "../modals/OtpSuccessModal";

const SignUp = () => {
  let { user } = useSelector((store) => store.user);
  const [errorMsg, setErrorMsg] = useState("");
  const [referralCodeState, setReferralCodeState] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get("referralCode");
  // console.log("referralCode", referralCode);

  useEffect(() => {
    if (referralCode) {
      setReferralCodeState(referralCode);
    }
  }, [referralCode]);

  const navigate = useNavigate();
  useEffect(() => {
    if (user?.email_verified_at) {
      // navigate("/");
    }
  }, [user]);
  // console.log("USER DATA COM:",user)
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [showOTPSuccess, setShowOTPSuccess] = useState(false);

  const [value, setValue] = useState();
  const [pass, setPass] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);

  // SIGNUP API INTEGRATION
  const dispatch = useDispatch();

  const { mutate, isPending } = useMutation({
    mutationFn: apis.authRegister,
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data: user, status }) => {
      console.log("DATA-USER-SIGNUP:", user);
      if (user?.status) {
        console.log("inside 200");
        dispatch(setUser(user?.data));
        setShowVerifyOTP(true);
      }
    },
  });

  const handleSubmit = (values, actions) => {
    // console.log("VALUES:", values);
    // console.log("VALUE:::", value);
    if (!value) {
      setErrorMsg("Phone number is required");
      // toast.error("Phone number cannot be empty");
      return;
    }
    setErrorMsg("");
    // value = "12345678"
    if (values && value) {
      mutate({ ...values, phone_no: value });
    }
    actions.resetForm();
    setValue("");
  };

  // console.log("isPending...", isPending);

  return (
    <>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          // phone_no: "",
          password: "",
          confirm_password: "",
          referral_code: referralCode || "",
        }}
        onSubmit={handleSubmit}
        validationSchema={SignupValidationSchema}
      >
        {({ errors, touched }) => (
          <div className="signup-wrapper">
            <div className="signup-left">
              <div className="signup-logo">
                <a href="/">
                  <img
                    src="/assets/images/infyom.png"
                    alt="logo"
                    className="logimg"
                  />
                </a>
              </div>
              <div className="left">
                <Form
                  method="POST"
                  className="form-log resize-form mar-top signup-form"
                >
                  <h4>SIGN UP</h4>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="">First Name</label>
                      <div className="input-con">
                        <Field
                          id="first_name"
                          placeholder="Enter First Name"
                          type="text"
                          // className="form-control"
                          name="first_name"
                          autoComplete="first_name"
                          className={
                            errors.first_name && touched.first_name
                              ? "error-input"
                              : ""
                          }
                        />

                        <img
                          src="/assets/images/form-icon/profile-name.png"
                          alt="user"
                          className="pass-icon"
                        />
                      </div>
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="">Last Name</label>
                      <div className="input-con">
                        <Field
                          id="last_name"
                          placeholder="Enter Last Name"
                          type="text"
                          name="last_name"
                          autoComplete="last_name"
                          className={
                            errors.last_name && touched.last_name
                              ? "error-input"
                              : ""
                          }
                        />
                        <img
                          src="/assets/images/form-icon/profile-name.png"
                          alt="user"
                          className="pass-icon"
                        />
                      </div>
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="error-message"
                      />
                      <span
                        id="last_name-error"
                        className="error-message"
                      ></span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="">Email</label>
                      <div className="input-con">
                        <Field
                          id="email"
                          placeholder="Enter Your Email"
                          type="email"
                          name="email"
                          autoComplete="email"
                          className={
                            errors.email && touched.email ? "error-input" : ""
                          }
                        />
                        <img
                          src="/assets/images/form-icon/pofile-email.png"
                          alt="user"
                          className="pass-icon"
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="">Referral Code</label>
                      <div className="input-con">
                        <Field
                          id="refer_code"
                          type="text"
                          className="input"
                          name="referral_code"
                          // placeholder="0aBFT20"
                          value={referralCodeState}
                          disabled
                        />
                        <img
                          src="/assets/images/referal.png"
                          alt="user"
                          className="pass-icon"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="">Phone Number</label>
                      <div className="input-con phone">
                        <div>
                          <PhoneInput
                            placeholder="Enter phone number"
                            value={value}
                            // rules={{ required: true }}
                            onChange={setValue}
                            name="phone_no"
                          />
                        </div>
                      </div>
                      <p className="error-message">{errorMsg && errorMsg}</p>
                      {/* {
                      value && <span id="phone_no-error" className="error-message"></span>
                    } */}
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="">Password</label>
                      <div className="" id="show_hide_password">
                        <div className="input-con">
                          <Field
                            id="password"
                            type={pass ? "text" : "password"}
                            placeholder="Enter Password"
                            // className="form-control "
                            name="password"
                            autoComplete="new-password"
                            className={
                              errors.password && touched.password
                                ? "error-input"
                                : ""
                            }
                          />
                          <img
                            src="/assets/images/icons/pass-icon.png"
                            className="pass-icon"
                            alt=""
                          />
                          {pass ? (
                            <span>
                              <FaRegEye
                                className="eye"
                                onClick={() => {
                                  setPass(!pass);
                                }}
                              />
                            </span>
                          ) : (
                            <span>
                              <FaRegEyeSlash
                                className="eye"
                                onClick={() => {
                                  setPass(!pass);
                                }}
                              />
                            </span>
                          )}
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-message"
                        />
                        {/* <span
                        id="password-error"
                        className="error-message"
                      ></span> */}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="">Confirm Password</label>
                      <div className="" id="show_hide_password2">
                        <div className="input-con">
                          <Field
                            id="password_confirmation"
                            type={confirmPass ? "text" : "password"}
                            placeholder="Enter Password"
                            // className="form-control"
                            name="confirm_password"
                            autoComplete="new-password"
                            className={
                              errors.confirm_password &&
                              touched.confirm_password
                                ? "error-input"
                                : ""
                            }
                          />
                          <img
                            src="/assets/images/icons/pass-icon.png"
                            className="pass-icon"
                            alt=""
                            onClick="togglePass()"
                          />
                          {confirmPass ? (
                            <span>
                              <FaRegEye
                                className="eye"
                                onClick={() => {
                                  setConfirmPass(!confirmPass);
                                }}
                              />
                            </span>
                          ) : (
                            <span>
                              <FaRegEyeSlash
                                className="eye"
                                onClick={() => {
                                  setConfirmPass(!confirmPass);
                                }}
                              />
                            </span>
                          )}
                        </div>
                        <ErrorMessage
                          name="confirm_password"
                          component="div"
                          className="error-message"
                        />
                        {/* <span
                        id="password_confirmation-error"
                        className="error-message"
                      ></span> */}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <label
                        for=""
                        style={{ color: "#fff" }}
                        className="label-signup"
                        htmlFor=""
                      >
                        ..
                      </label>
                      <div className="signin-wrapper-btn">
                        <button
                          type="submit"
                          className="logbtn signup-btn resize-btn"
                          id="signup-form"
                          disabled={isPending}
                        >
                          {isPending ? "Loading..." : " Sign up"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className=" mt-3 col-12">
                    <div className="col-lg-12 col-md-12 col-12 my-auto">
                      <p className="p-item my-4">
                        Already have an account?{" "}
                        <Link to="/login" className="redirect">
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="right">
              <video
                width="100%"
                height="100%"
                autoPlay="autoplay"
                playsInline=""
                style={{
                  pointerEvents: "none",
                  borderRadius: "20px 0 0 20px",
                  background: "#333333",
                }}
              >
                <source
                  src="https://www.youtube.com/watch?v=tzSXjwkFnVc"
                  type="video/mp4"
                />
                Your browser does not support the video t
              </video>
            </div>
          </div>
        )}
      </Formik>
      <VerifyOTPModal
        showVerifyOTP={showVerifyOTP}
        setShowVerifyOTP={setShowVerifyOTP}
        showOTPSuccess={showOTPSuccess}
        setShowOTPSuccess={setShowOTPSuccess}
      />
      <OtpSuccessModal
        showOTPSuccess={showOTPSuccess}
        setShowOTPSuccess={setShowOTPSuccess}
        showVerifyOTP={showVerifyOTP}
        setShowVerifyOTP={setShowVerifyOTP}
      />
    </>
  );
};

export default SignUp;
