import React from "react";
import { Link } from "react-router-dom";
import ProductCart from "./ProductCard";
import { ProductCard } from ".";

const CategoryAllProducts = ({
  whereIsUsed,
  index,
  data,
  className,
  limit,
  onLoadMore,
}) => {
  // console.log("CATEGORY WISE DATA:", data);
  return (
    <section className="home-sec-3" id={`${data?.id}`} key={index}>
      {whereIsUsed === "home" && (
        <>
          {index % 2 === 0 ? (
            <>
              <div className="left-side-img">
                <img src="/assets/images/side-img1.png" alt="img1" />
                {data?.products?.length > 3 && (
                  <img src="/assets/images/side-img2.png" alt="img2" />
                )}
              </div>

              <div className="right-side-img">
                <img src="/assets/images/side-img3.png" alt="img3" />
              </div>
            </>
          ) : (
            <>
              <div className="left-side-img">
                <img
                  src="/assets/images/side-img6.png"
                  alt="img1"
                  className="images-0"
                />
                {data?.products?.length > 3 && (
                  <img src="/assets/images/side-img5.png" alt="img2" />
                )}
              </div>
              <div className="right-side-img">
                <img src="/assets/images/side-img4.png" alt="img3" />
              </div>
            </>
          )}
        </>
      )}

      <div className="wrapper container">
        <div className="container">
          {/* {console.log("DATA IN CATEGORY ALL PRODUCTS:",data)} */}
          {data?.products?.length > 0 && (
            <>
              <div className="main-heading">
                <h2>{data?.name}</h2>
              </div>

              <div className="row">
                {data?.products?.slice(0, limit).map((data, index) => {
                  return (
                    <ProductCard
                      key={index}
                      className={className}
                      data={data}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>

      {whereIsUsed === "home"
        ? data?.products?.length > 6 && (
            <div className="btn-viewmore">
              <Link to={`/viewall/${data.id}`}>
                <button>View all</button>{" "}
              </Link>
              <img
                src="/assets/images/icons/drag-viewmore.png"
                alt="viewmore"
              />
            </div>
          )
        : data?.products?.length > limit && (
            <div className="btn-loadmore">
              <button onClick={onLoadMore}>Load More</button>
            </div>
          )}
    </section>
  );
};

export default CategoryAllProducts;
