import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = () => {
  const { pathname } = useLocation();
  const { user } = useSelector((store) => store.user);

  return user ? (
    <Outlet />
  ) : (
    <Navigate to={`/login`} state={{ from: pathname }} />
  );
};

export default ProtectedRoute;
