import React from "react";
import { useSelector } from "react-redux";

const CategoryCard = ({ data, index }) => {
  let { product } = useSelector((product) => product.product);
  // console.log("PRODUCT ID STORE", product);
  // console.log("SLIDER PRODUCTS ID:", data.id);

  const handleClick = (ID) => {
    // window.scrollTo({ top: 0 });
    document
      .getElementById(ID)
      ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
  };

  return (
    <div
      // id={`card-${data.id}`}
      className="one"
      key={index}
      onClick={() =>handleClick(data?.id)}
    >
      <div className="img-container">
        <img src={data?.image} alt="products" />
      </div>
      <div className="category-name">
        <p> {data?.name} </p>
      </div>
    </div>
  );
};

export default CategoryCard;
