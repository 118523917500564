import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Modal from "react-bootstrap/Modal";
import CheckoutFormToken from "../CheckoutFormToken";

const PaymentModalToken = ({ showModal, handleToggleModal, promise, data }) => {
  return (
    <Modal
      show={showModal}
      size="lg"
      onHide={handleToggleModal}
      backdrop="static"
      keyboard={false}
      centered
      className="modal-jtcToken"
    >
      <Modal.Header className="bg-gold border-0 " closeButton>
        <div className="img-wrapper-checkout">
          <img src="./assets/images/infyom.png" alt="logo" />
        </div>
      </Modal.Header>
      <Modal.Body className="modal_pay">
        <div class="position-relative">
          <h4 className="position-absolute top-50 start-50 translate-middle text-purple checkout-head">
            CHECKOUT
          </h4>
        </div>

        <div className="row align-items-start p-3">
          <div className="col-md-12 my-3 detail-checkout">
            <Elements stripe={promise}>
              <CheckoutFormToken
                handlePaymentModal={handleToggleModal}
                dataToken={data}
              />
            </Elements>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModalToken;
