import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { changePasswordSchema } from "../../helper/validationSchema";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import apis from "../../services";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FaRegEyeSlash } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa6";

const ChangePassword = () => {
  let { user } = useSelector((store) => store.user);
  const [currentPass, setCurrentPass] = useState(false);
  const [pass, setPass] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);

  const { mutate, isPending } = useMutation({
    mutationFn: apis.authChangePassword,
    onError: function ({ message }) {
      //   console.log("MESSAGE ERROR:", message);
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data, status }) => {
      console.log("data:", data);
      if (data?.status) {
        toast.success("Password Changed Successfully!", { id: 1 });
      }
    },
  });

  const handleSubmit = (values, actions) => {
    console.log("VALUES:", values);
    let email = user.email;
    // console.log("EMAIL:", email);
    if (values && email) {
      mutate({ ...values, email });
    }
    actions.resetForm();
  };
  return (
    <Formik
      initialValues={{
        current_password: "",
        new_password: "",
        confirm_password: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={changePasswordSchema}
    >
      {({ errors, touched }) => (
        <div className="confirm-pasword">
          {/* <div className="top-logo">
            <Link to="/">
              <img
                src="./assets/images/infyom.png"
                alt="logo"
                className="logimg"
              />
            </Link>
          </div> */}
          <Form method="POST">
            <div className="forgot-input-container">
              <div className="input-wrapper" style={{ gap: "0" }}>
                <label> Current Password</label>
                <div className="input-change-pass-con">
                  <Field
                    type={currentPass ? "text" : "password"}
                    name="current_password"
                    id="current_password"
                    placeholder="Enter Your Current Password"
                    // value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                    className={
                      errors.current_password && touched.current_password
                        ? "error-input"
                        : ""
                    }
                  />
                  {currentPass ? (
                    <span>
                      <FaRegEye
                        className="eye"
                        onClick={() => {
                          setCurrentPass(!currentPass);
                        }}
                      />
                    </span>
                  ) : (
                    <span>
                      <FaRegEyeSlash
                        className="eye"
                        onClick={() => {
                          setCurrentPass(!currentPass);
                        }}
                      />
                    </span>
                  )}
                </div>
                <ErrorMessage
                  name="current_password"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="input-wrapper" style={{ gap: "0" }}>
                <label style={{ marginTop: "12px" }}> New Password</label>
                <div className="input-change-pass-con">
                  <Field
                    type={pass ? "text" : "password"}
                    name="new_password"
                    id="new_password"
                    placeholder="Enter Your New Password"
                    // value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                    className={
                      errors.new_password && touched.new_password
                        ? "error-input"
                        : ""
                    }
                  />
                  {pass ? (
                    <span>
                      <FaRegEye
                        className="eye"
                        onClick={() => {
                          setPass(!pass);
                        }}
                      />
                    </span>
                  ) : (
                    <span>
                      <FaRegEyeSlash
                        className="eye"
                        onClick={() => {
                          setPass(!pass);
                        }}
                      />
                    </span>
                  )}
                </div>
                <ErrorMessage
                  name="new_password"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="input-wrapper" style={{ gap: "0" }}>
                <label style={{ marginTop: "12px" }}>Confirm Password</label>
                <div className="input-change-pass-con">
                  <Field
                    type={confirmPass ? "text" : "password"}
                    name="confirm_password"
                    id="confirm_password"
                    placeholder="Enter Your Confirm Password "
                    // value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                    className={
                      errors.confirm_password && touched.confirm_password
                        ? "error-input"
                        : ""
                    }
                  />
                  {confirmPass ? (
                    <span>
                      <FaRegEye
                        className="eye"
                        onClick={() => {
                          setConfirmPass(!confirmPass);
                        }}
                      />
                    </span>
                  ) : (
                    <span>
                      <FaRegEyeSlash
                        className="eye"
                        onClick={() => {
                          setConfirmPass(!confirmPass);
                        }}
                      />
                    </span>
                  )}
                </div>
                <ErrorMessage
                  name="confirm_password"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="forgot-btn-wrapper">
                <button type="submit">
                 
                  {isPending ? "Loading..." : "Save"}
                </button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default ChangePassword;
