import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apis from "../../services";
import { ForgotValidationSchema } from "../../helper/validationSchema";
import { VerifyOTPModal } from "../modals/VerifyOTPModal";
import { useSelector } from "react-redux";

const ForgotPassword = () => {
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [showOTPSuccess, setShowOTPSuccess] = useState(false);

  let { user } = useSelector((store) => store.user);

  const navigate = useNavigate();
  useEffect(() => {
    if(user) {
      // navigate("/");
    }
  }, [user]);

  const [email, setEmail] = useState("");

  const { mutate: mutateForgot, isPending } = useMutation({
    mutationFn: apis.authForget,
    onError: function ({ message }) {
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data: userForgot, status }) => {
      console.log("userForgot:", userForgot);
      if (userForgot?.status) {
        setShowVerifyOTP(true)
      }
    },
  });

  const handleSubmit = (values) => {
    console.log("VALUES:", values);

    setEmail(values?.email)
    mutateForgot(values);
  };
  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={handleSubmit}
        validationSchema={ForgotValidationSchema}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="forgot-pasword ">
              {/* <div className="top-logo">
                <Link to="/">
                  <img
                    src="./assets/images/infyom.png"
                    alt="logo"
                    className="logimg"
                  />
                </Link>
              </div> */}
              <div className="forgot-input-container">
                <div className="input-wrapper">
                  <label>Forgot Password</label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Your Email"
                    // value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                    className={
                      errors.email && touched.email ? "error-input" : ""
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="forgot-btn-wrapper">
                  <button type="submit" >  {isPending ? "Loading..." : "Send"}</button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <VerifyOTPModal
      email={email}
        showVerifyOTP={showVerifyOTP}
        setShowVerifyOTP={setShowVerifyOTP}
        showOTPSuccess={showOTPSuccess}
        setShowOTPSuccess={setShowOTPSuccess}
      />
    </>
  );
};

export default ForgotPassword;
