import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";

const CartProceedTimer = () => {
  const location = useLocation();
  console.log("OrederStatusTimerData:", location);

  const [showButton, setShowButton] = useState(false);
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      const timeRemaining = calculateTimeRemaining();

      if (timeRemaining !== null) {
        const duration = moment.duration(timeRemaining);
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        const formattedTime = `${String(hours).padStart(2, "0")} : ${String(
          minutes
        ).padStart(2, "0")} : ${String(seconds).padStart(2, "0")}`;

        setFormattedTime(formattedTime);
      } else {
        clearInterval(timer);
        setShowButton(false);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [location.state]);

  const calculateTimeRemaining = () => {
    const now = moment();

    const startTime = moment(location.state?.start_time , "h:mm A");
    const endTime = moment(location.state?.end_time, "h:mm A");

    if (now.isBefore(startTime)) {
      // Contest hasn't started yet
      return startTime.diff(now, "milliseconds");
    } else if (now.isBefore(endTime)) {
      // Contest is ongoing
      return endTime.diff(now, "milliseconds");
    }
  };

  useEffect(() => {
    setShowButton(checkTime());
    const timer = setInterval(() => {
      setShowButton(checkTime());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [location.state]);

  const checkTime = () => {
    const currentTime = moment();
    return currentTime.isBetween(
      moment(location.state?.start_time , "h:mm A"),
      moment(location.state?.end_time, "h:mm A")
    );
  };

  console.log("checkTime", checkTime());
  console.log("showButton", showButton);
  console.log("formattedTime", formattedTime);
  console.log("calculateTimeRemaining", calculateTimeRemaining());
  return (
    <section id="proceed-timer">
      <div className="up">
        <div className="con">
          <h1>{formattedTime ? formattedTime : "00:00:00"}</h1>
          <p>Time Remaining</p>
        </div>
      </div>
      <div className="down">
        <div className="con">
          <img src="/assets/images/timer.png" alt="timer" />
          <p className="patience">Be Patient</p>
          {showButton && (
            <Link to="/">
              <button type="submit">GO HOME</button>
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default CartProceedTimer;
