import React from 'react'
import Modal from "react-bootstrap/Modal";

export const OtpSuccessModal = ({ showOTPSuccess, setShowOTPSuccess }) => {
    return (
        <Modal
            show={showOTPSuccess}
            onHide={() => setShowOTPSuccess(false)}
            centered
            size="lg"
            className="otp-success-modal"
            // backdrop="static"
            // keyboard={false}
        >
            <div className="modal-body" id="signup-verfied" bis_skin_checked="1">
                <img src="/assets/images/verified.png" alt="verified" />
                <h3>Verified!</h3>
                <p>Hurrah! You have successfully
                    verified the account</p>
            </div>
        </Modal>
    )
}
