import React from "react";
import RewardCard from "../components/RewardCard";
import { useQuery } from "@tanstack/react-query";
import apis from "../services";

const Reward = () => {
  const {
    isLoading,
    error,
    refetch,
    data: { data: getReferral } = {},
  } = useQuery({
    queryKey: ["getReferral"],
    queryFn: () => apis.getReferral(),
    onError: (error) => {
      console.error("Error geting Referral :", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  console.log("getReferral", getReferral);

  //GET WALLET API
  const {
    isLoading: loading,
    error: isError,

    data: { data: getUserWallet } = {},
  } = useQuery({
    queryKey: ["getUserWallet"],
    queryFn: () => apis.getUserWallet(),
    onError: (error) => {
      console.error("Error geting user wallet :", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  // console.log("USER WALLET", getUserWallet);

  return (
    <>
      {isLoading ? (
        <div className="loaderWrapper-cart">
          <div className="loader"></div>
        </div>
      ) : (
        <section className="container-fluid" id="jtc-token">
          <div className="heading-bg">
            <h2>REWARD</h2>
          </div>
          <div id="coupon-parent" className="reward-page">
            <div className="reward-left"></div>
            
            <div className="coupon-wrapper">
              <RewardCard data={getReferral} />
            </div>
            <div className="reward-right">
              <div className="up">
                <img src="/assets/images/frame-reward.png" alt="frame-reward" />
                <p>JTC Points</p>
              </div>
              <div className="down">
                <h1>
                  {getUserWallet?.availableTokens !== null &&
                  getUserWallet?.availableTokens !== undefined
                    ? getUserWallet?.availableTokens
                    : 0}{" "}
                  <span>Available</span>
                </h1>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default Reward;
