import React, { useEffect, useState } from "react";
import moment from "moment";

const SliderCard = ({ index, data }) => {
  console.log("DATA,",data)
  const [showButton, setShowButton] = useState(false);
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      const timeRemaining = calculateTimeRemaining();

      if (timeRemaining !== null) {
        const duration = moment.duration(timeRemaining);
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        const formattedTime = `${String(hours).padStart(2, "0")} : ${String(
          minutes
        ).padStart(2, "0")} : ${String(seconds).padStart(2, "0")}`;

        setFormattedTime(formattedTime);
      } else {
        clearInterval(timer);
        setShowButton(false);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [data]);

  const calculateTimeRemaining = () => {
    const now = moment();

    const startTime = moment(data?.OpeningTime, "h:mm A");
    const endTime = moment(data?.ClosingTime, "h:mm A");

    if (now.isBefore(startTime)) {
      // Contest hasn't started yet
      return startTime.diff(now, "milliseconds");
    } else if (now.isBefore(endTime)) {
      // Contest is ongoing
      return endTime.diff(now, "milliseconds");
    }
  };

  useEffect(() => {
    setShowButton(checkTime());
    const timer = setInterval(() => {
      setShowButton(checkTime());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [data]);

  const checkTime = () => {
    const currentTime = moment();
    return currentTime.isBetween(
      moment(data?.OpeningTime, "h:mm A"),
      moment(data?.ClosingTime, "h:mm A")
    );
  };

  // console.log("checkTime", checkTime());
  // console.log("showButton", showButton);
  // console.log("formattedTime", formattedTime);
  // console.log("calculateTimeRemaining", calculateTimeRemaining());

  return (
    <div key={index}>
      <div
        className="main-slide-wrapper"
        style={{
          backgroundImage: `url('./assets/images/home-sec-one.png')`,
          height: "611px",
        }}
      >
        {checkTime() ? (
          <>
            <h1>{data?.Discount}% OFFER</h1>
            <div className="offer-timer">
              <p className="timer">{formattedTime ? formattedTime : "0:0:0"}</p>
              <ul>
                <li>HOURS</li>
                <li>MINUTES</li>
                <li>SECONDS</li>
              </ul>
            </div>
            <p className="desc">
              Avail JTC TOKENS <br />
              FROM THIS OFFER
            </p>
          </>
        ) : (
          <>
            <p className="desc">
              Indulge in the rich flavors of JTC Coffee <br /> for an
              unforgettable experience
            </p>
          </>
        )}

        <div className="button-wrap">
          <button>
            Explore now
            <svg
              width={33}
              height={33}
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="16.2"
                cy="16.1"
                r="12.4639"
                transform="rotate(-15 16.2 16.1)"
                stroke="white"
                strokeWidth="1.2"
              />
              <path
                d="M21.7729 13.7897C21.6723 13.7243 21.6181 13.6875 21.5626 13.6532C20.633 13.0793 19.7052 12.505 18.7744 11.9336C18.5019 11.7665 18.3346 11.5384 18.3364 11.1993C18.3365 10.6165 18.8987 10.2586 19.4001 10.5162C19.4379 10.5348 19.4731 10.5582 19.5078 10.5797C21.0121 11.5125 22.5178 12.443 24.0227 13.3777C24.453 13.6441 24.5767 14.0912 24.3398 14.5322C23.4987 16.1038 22.6507 17.6711 21.8166 19.2469C21.4757 19.8903 20.7426 19.7256 20.4773 19.2323C20.327 18.9524 20.3683 18.689 20.5087 18.4277C20.9959 17.5235 21.4795 16.6202 21.9667 15.716C22.0285 15.601 22.1037 15.4926 22.1719 15.3799C22.1596 15.3627 22.1467 15.3435 22.1344 15.3263C22.0678 15.3442 21.9994 15.3625 21.9327 15.3804C17.8328 16.479 13.7346 17.5771 9.63464 18.6756C9.5176 18.707 9.39516 18.7398 9.2766 18.7367C8.92375 18.7307 8.64954 18.4491 8.57704 18.0417C8.5153 17.6889 8.69402 17.3475 9.01276 17.219C9.09791 17.1839 9.19025 17.1612 9.27848 17.1376C13.3604 16.0438 17.4442 14.9496 21.5262 13.8558L21.7711 13.7902L21.7729 13.7897Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SliderCard;
