import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { GrFormPreviousLink } from "react-icons/gr";
import { GrFormNextLink } from "react-icons/gr";
import CategoryCard from "./shared/categoryCard";
import { useQuery } from "@tanstack/react-query";
import apis from "../services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import reduxApis from "../redux/api";

const HomeCategorySection = () => {
  
  let { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const {
    isLoading,
    error,
    data: { data: getAllProduct } = {},
  } = useQuery({
    queryKey: ["getAllProductWithCategory"],
    queryFn: () => apis.getAllProductWithCategory(),
    onError: (error) => {
      console.error("Error fetching products:", error);
      toast.error("Failed to fetch products. Please try again later.");
    },
  });
  const fetchData = () => {
    dispatch(reduxApis.getAllProductWithCategory(user?.id));
  };
  useEffect(() => {
    fetchData();
  }, []);



  console.log("DATA ALL PRODUCT:", getAllProduct);
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    slidesToShow: 4,
    dots: false,
    arrows: true,
    swipeToSlide: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      //   {
      //     breakpoint: 480,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 1
      //     }
      //   }
    ],
  };
  return (
    <>
      <section className="home-sec-2 container">
        {isLoading ? (
          <>
            <div className="loaderWrapper">
              <div className="loader"></div>
            </div>
          </>
        ) : (
          <>
            <div id="wrapper-category-slider">
              <div className="category-parent" id="category-parent">
                <div className="sec2-main-heading">
                  <div className="">Food Category</div>
                </div>
                {getAllProduct?.data?.length > 4 && (
                  <div className="category-btn-prev-next">
                    <GrFormPreviousLink
                      onClick={previous}
                      className="category-prev"
                      size={50}
                    />
                    <GrFormNextLink
                      onClick={next}
                      className="category-next"
                      size={50}
                    />
                  </div>
                )}
                <div className="category-sliderr" id="category-list">
                  <Slider
                    ref={(slider) => {
                      sliderRef = slider;
                    }}
                    {...settings}
                  >
                    {getAllProduct?.data?.map((data, index) => {
                      return (
                        <CategoryCard index={index} data={data} key={index} />
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default HomeCategorySection;
