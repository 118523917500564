import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

function GoogleSearchInput() {
  <div>
    <GooglePlacesAutocomplete apiKey="AIzaSyDLSQ4yAL3LqVTuqVZWUMshrNTGoaDtcII" />
  </div>;
}

export default GoogleSearchInput;
