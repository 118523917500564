import React, { useState } from "react";
import apis from "../services";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { clearCart } from "../redux/slice/productSlice";
import { useDispatch } from "react-redux";
import { MdDelete } from "react-icons/md";

const Cart = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isLoading,
    isPending: isPendingCart,
    error,
    refetch,
    data: { data: productCart } = {},
  } = useQuery({
    queryKey: ["getProductCart"],
    queryFn: () => apis.getViewCart(),
    onError: (error) => {
      console.error("Error fetching products:", error);
      toast.error("Failed to fetch products. Please try again later.");
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => apis.addToCart(data),
    onError: function ({ message }) {
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data: addToCart, status , message }) => {
      if (status === 200) {
        refetch();
        toast.success(addToCart?.message);
      }
    },
  });

  const { mutate: mutateClearCart } = useMutation({
    mutationFn: () => apis.clearCart(),
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data: addToCart, status }) => {
      if (status === 200) {
        refetch();
        dispatch(clearCart());
        toast.success("Cart Clear Successfully!");
      }
    },
  });

  const transformAddonData = (addons) => {
    return addons.map((addon) => ({
      addon_id: addon.addon_id,
      addon_items_id: addon.addon_items.map((item) => item.item_id),
    }));
  };

  const decrement = (cart_id, product_id, addons, quantity, product_variation) => {
   
    if(product_variation !== null && product_variation !== "")
   { 
    const newDataCart = {
      cart_id: cart_id,
      product_id: product_id,
      addons: transformAddonData(addons),
      quantity: quantity,
      product_variation:product_variation
    };
if(quantity >= 1)
{
  mutate(newDataCart);
}
    }
    else{
      const newDataCart = {
        cart_id: cart_id,
        product_id: product_id,
        addons: transformAddonData(addons),
        quantity: quantity
      };
  if(quantity >= 1)
  {
    mutate(newDataCart);
  }
    }
  
  };




  const increment = (product_id, addons, quantity,product_variation) => {
    if(product_variation !== null && product_variation !== "")
    {
      const newDataCart = {
        product_id: product_id,
        addons: transformAddonData(addons),
        quantity: quantity,
        product_variation:product_variation
      };
  
      mutate(newDataCart);
    
    }
    else{
      const newDataCart = {
        product_id: product_id,
        addons: transformAddonData(addons),
        quantity: quantity
      };
  
      mutate(newDataCart);
    
    }

    };

  const proceedCheckout = () => {
    Navigate("/checkout", { state: productCart });
  };
  console.log("productCart", productCart);

  const deleteCart = async (cart_id) => {
    console.log("CLICK", cart_id);
    try {
      console.log("CLICK");
      const response = await apis.removeProductFromCart({
        id: Number(cart_id),
      });
      console.log("RES", response);
      if (response.status === 200) {
        refetch(); // Refetch the cart data to update UI
        toast.success("Item removed from cart successfully!");
      } else {
        toast.error("Failed to remove item from cart. Please try again later.");
      }
    } catch (error) {
      console.error("Error removing item from cart:", error);
      toast.error("Failed to remove item from cart. Please try again later.");
    }
  };

  console.log(productCart?.data?.cart,"productCart?.data?.cart");


  const getPriceBySize = (productData, size) => {
    // Check if productData exists and is_variant_check is enabled
    if (productData && productData.is_variant_check === 1) {
      // Determine the property name based on the size argument
      const propertyName = `${size}_price`;
      // Check if the property exists in the productData
      if (propertyName in productData) {
        // Return the price corresponding to the size
        return productData[propertyName];
      } else {
        // Return null if the size property is not found
        return null;
      }
    } else {
      // Return null if variant checking is disabled
      return null;
    }
  };
  

  return (
    <>
      {isLoading || isPendingCart ? (
        <>
          <div className="loaderWrapper-cart">
            <div className="loader"></div>
          </div>
        </>
      ) : productCart?.data?.cart.length > 0 ? (
        <div className="" id="cart">
          <div className="d-flex justify-content-between">
            <h1>CART</h1>
            <button onClick={mutateClearCart} className="clear-cart">
              CLEAR CART
            </button>
          </div>
          <div className="main-wrapper">
            <div className="main-parent">
              <div className="left">
                {productCart?.data?.cart.map((data, index) => {
                  const { product_detail } = data;
                  return (
                    <div className="cart-wrapper-wrapper" key={index}>
                      <div className="cart-wrapper">
                        <img
                          src={
                            product_detail?.images?.[0] ||
                            "/assets/images/cart/img-1.png"
                          }
                          alt="img-1"
                          className="cart-img"
                        />
                        <div className="con">
                          <div className="up">
                            <h3>{product_detail?.name}</h3>
                            <p>{product_detail?.description}</p>
                          </div>
                          {/* <div className="down">
                            <p>
                              {product_detail?.product_addons.length > 0 && (
                                <span>Addons: </span>
                              )}
                              <span>
                                {product_detail?.product_addons.map(
                                  (data, index) => {
                                    return data?.addon_items.map(
                                      (data, index) => {
                                        console.log("ADDONS ITEM NAME:", data);
                                        return (
                                          <span key={index}>
                                            {data?.item_name}
                                          </span>
                                        );
                                      }
                                    );
                                  }
                                )}
                              </span>
                            </p>
                          </div> */}
                        </div>
                      </div>

                      <div className="quantity-wrapper">
                        <div className="delete-cart-price-wrapper">
                          <h2>${product_detail?.is_variant_check == 1 ? getPriceBySize(product_detail,data?.product_variation) * Number(data?.quantity) : Number(product_detail?.product_price) * Number(data?.quantity)} </h2>
                          <MdDelete
                            className="cart-delete"
                            onClick={() => deleteCart(data?.id)}
                          />
                        </div>
                        <div className="quantity-con">
                          <p className="qty">QTY</p>
                          <button
                            className="minus"
                            onClick={() =>
                              decrement(
                                data?.id,
                                product_detail?.id,
                                product_detail?.product_addons,
                                data?.quantity - 1,
                                data?.product_variation
                              )
                            }
                          >
                            -
                          </button>
                          <p className="counter" id="counterValue1">
                            {data?.quantity}
                          </p>
                          <button
                            className="plus"
                            onClick={() =>
                              increment(
                                product_detail?.id,
                                product_detail?.product_addons,
                                1,
                                data?.product_variation
                              )
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <button className="add-more">ADD MORE</button> */}
              </div>
            </div>

            <div className="right">
              <div className="two">
                <h2>ORDER SUMMARY</h2>
                <div className="summary-wrapper">
                  <h2>Fiat</h2>
                  <div className="up">
                    <div className="one">
                      <p className="p1">Order Total</p>
                      <p className="p2">${Number(productCart?.data?.subtotal).toFixed(2)}</p>
                    </div>
                    {/* <div className="one">
                      <p className="p1">Delivery Charges</p>
                      <p className="free">{productCart?.data?.delivery}</p>
                    </div> */}
                    <div className="one">
                      <p className="p1">Tax</p>
                      <p className="free">
                        {Number(productCart?.data?.tax).toFixed(2)}
                      </p>
                    </div>
                    <div className="one">
                      <p className="p1">Grand Total</p>
                      <p className="p2">${Number(productCart?.data?.total).toFixed(2)}</p>
                    </div>
                  </div>
                  <h2 className="mt-3">JTC Token</h2>
                  <div className="up">
                    <div className="one">
                      <p className="p1">Order Total</p>
                      <p className="p2">${Number(productCart?.data?.tokens).toFixed(2)}</p>
                    </div>
                    {/* <div className="one">
                      <p className="p1">Delivery Charges</p>
                      <p className="free">{productCart?.data?.tokens_delivery}</p>
                    </div> */}
                    <div className="one">
                      <p className="p1">Tax</p>
                      <p className="free">{Number(productCart?.data?.tax_token).toFixed(2)}</p>
                    </div>
                    <div className="one">
                      <p className="p1">Grand Total</p>
                      <p className="p2">{Number(productCart?.data?.total_token).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="down">
                    <button onClick={proceedCheckout}>
                      PROCEED TO CHECKOUT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-wrapper " id="cart">
          <div className="main-parent">
            <div className="d-flex justify-content-center align-items-center mt-5 p-4">
              <h1>CART EMPTY</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
