import { useQuery } from "@tanstack/react-query";
import React from "react";
import apis from "../services";
import { Link, useNavigate } from "react-router-dom";

const Order = () => {
  const Navigate = useNavigate();
  const handleTimerComponent = (data) => {
    console.log("DATA", data);
    Navigate("/order-status", { state: data });
  };

  const {
    isLoading,
    error,
    refetch,
    data: { data: getOrderHistory } = {},
  } = useQuery({
    queryKey: ["getOrderHistory"],
    queryFn: () => apis.getOrderHistory(),
    onError: (error) => {
      console.error("Error geting Order History:", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  // console.log("getOrderHistory", getOrderHistory);
  return (
    <>
      {isLoading ? (
        <>
          <div className="loaderWrapper-cart">
            <div className="loader"></div>
          </div>
        </>
      ) : (
        <section className="container-fluid" id="jtc-token">
          <div className="heading-bg">
            <h2>MY ORDER</h2>
          </div>
          <div id="order-card-parent">
            {getOrderHistory?.data.length > 0 ? (
              getOrderHistory?.data.map((data, index) => {
                // console.log("DATA:", data);
                return (
                  <>
                    <div className="order-card" key={index}>
                      <div className="left">
                        <div className="img-con">
                          <img src="/assets/images/Frame.png" alt="product" />
                        </div>
                        <div className="text-con">
                          <p>Order Id: {data?.id}</p>
                          <div className="one">
                            {/* <p>
                      QTY: <span>02</span>
                    </p> */}
                            <p>Price: ${data?.grandTotal}</p>
                          </div>
                          <p className="order-date-text">Date: {data?.date}</p>
                        </div>
                      </div>
                      <div className="right">
                        <button className={`preparing ${data?.order_status} `}>
                          {data?.order_status}
                        </button>
                      </div>
                    </div>
                    {data.order_status === "processing" &&
                    <div className="btn-order-status">
                    <button onClick={() => handleTimerComponent(data)}>
                      View Order Status
                    </button>
                  </div>
 
                    }
                                     </>
                );
              })
            ) : (
              <p className="no-order">No Order Found</p>
            )}
            {/* <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="preparing">Preparing</button>
          </div>
        </div> */}
            {/* <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="done">Done</button>
          </div>
        </div>
        <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="dispatched">Dispatched</button>
          </div>
        </div>
        <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="done">Done</button>
          </div>
        </div>
        <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="reject">Reject</button>
          </div>
        </div>
        <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="dispatched">Dispatched</button>
          </div>
        </div>
        <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="preparing">Preparing</button>
          </div>
        </div> */}
          </div>
        </section>
      )}
    </>
  );
};

export default Order;
