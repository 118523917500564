import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";

const Footer = () => {
  const location = useLocation();
  const routesWithoutFooter = ["/login", "/signup"];

  const [shouldRenderFooter, setShouldRenderFooter] = useState(
    !routesWithoutFooter.includes(location.pathname)
  );
  useEffect(() => {
    setShouldRenderFooter(!routesWithoutFooter.includes(location.pathname));
  }, [location.pathname]);
  return (
    <React.Fragment>
      {shouldRenderFooter && (
        <footer className="">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 align-self-center increase">
                <div className="left">
                  <Link to="/">
                    <img
                      src="./assets/images/infyom.png"
                      alt=""
                      style={{ width: 150 }}
                    />
                  </Link>
                  {/* <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    OUR MISSION
                  </p> */}
                  <p className="para muted">
                    Our mission is to be the leading Blockchain Technology
                    provider for the specialty coffee industry. Committed to
                    top-quality products and exceptional service, we aim to
                    create opportunities for our employees and showcase our
                    expertise. Our goal is to make a positive impact on the
                    industry by demonstrating the value of Blockchain
                    Technology, one customer at a time.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 increase">
                <div className="center">
                  <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    CONTACT US
                  </p>
                  <p className="para muted">
                    Welcome to Java Times Caffé! We prioritize both
                    professionalism and fun, taking pride in delivering
                    exceptional service. For contact details, visit our website
                    or social media. Feel free to email us. We're here to help
                    for an unforgettable Java Times Caffé experience! Calle
                    Francisco Zarco 524 Ote, Colonia Centro Gomes Palacio
                    Durango CODIGO POSTAL 35000 +52(871) 1161608
                  </p>
                  {/* <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    OUR MISSION
                  </p>
                  <p className="para muted">
                    Our mission is to be the leading Blockchain Technology
                    provider for the specialty coffee industry. Committed to
                    top-quality products and exceptional service, we aim to
                    create opportunities for our employees and showcase our
                    expertise. Our goal is to make a positive impact on the
                    industry by demonstrating the value of Blockchain
                    Technology, one customer at a time.
                  </p> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12 increase">
                <div className="right">
                  <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    Newsletter
                  </p>
                  <p className="para muted">No worries, we don‘t spam</p>
                  <div className="joint-input my-3">
                    <input type="email" placeholder="Your Email" />
                    <button className="button primary-button">SUBMIT</button>
                  </div>
                  <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    Follow Us
                  </p>
                  <div className="flex-list my-2">
                    <Link
                      to="https://twitter.com/javatimescaffe?t=Q2myIHKv4waNHL3Wev-Q-g&s=09"
                      target="_blank"
                    >
                      <img
                        src="./assets/images/icons/twitter.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </Link>
                    <Link
                      to="https://www.youtube.com/watch?v=13bmTsSj54I&t=4s"
                      target="_blank"
                    >
                      <img
                        src="./assets/images/icons/youtube.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </Link>
                    <Link
                      to="https://www.tiktok.com/@antonioleite9"
                      target="_blank"
                    >
                      <img
                        src="./assets/images/icons/tiktok.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </Link>
                    <Link
                      to="https://www.facebook.com/JavaTimesCaffe?mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <img
                        src="./assets/images/facebook.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </Link>
                    <Link
                      to="https://www.instagram.com/javatimescaffe/?igshid=NmE0MzVhZDY%3D"
                      target="_blank"
                    >
                      <img
                        src="./assets/images/instagram.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </React.Fragment>
  );
};

export default Footer;
